import { Box, Divider, makeStyles, Menu, MenuItem } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  AccountCircle,
  Assessment,
  EditLocationOutlined,
  ExitToApp,
  SettingsInputAntenna,
} from '@material-ui/icons';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BugReportIcon from '@material-ui/icons/BugReport';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MapIcon from '@material-ui/icons/Map';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import { Auth } from 'aws-amplify';
import { ReactComponent as CollarIcon } from './collar.icon.svg';
import { ReactComponent as SteakIcon } from './steak.icon.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
  },
  bar: {
    backgroundColor: '#444',
    display: 'flex',
    padding: '0 1rem',
  },
  title: {
    color: '#fff',
    flexGrow: 1,
    paddingTop: '0.75rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
  },
  debugButton: {
    color: '#aaa',
    '&:hover': {
      color: '#fff',
    },
    background: 'transparent',
    border: 'none',
  },
}));

const NavigationBar: FunctionComponent = () => {
  const now = new Date();
  let title = 'Caddie';
  if (now.getMonth() + 1 === 8 && now.getDate() === 13) {
    title = 'Happy Birthday Jacquie!';
  }
  const [user, setUser] = useState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const classes = useStyles();

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: false }).then((u) => {
      setUser(u.attributes.given_name);
    });
  }, []);

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Auth.signOut();
    handleMenuClose();
  };

  const menuId = 'account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box style={{ textAlign: 'right', padding: '8px 16px' }}>Hi {user}</Box>
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ExitToApp style={{ paddingRight: '8px', color: '#aaa' }} />
        Sign out
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.wrapper}>
      <Box className={classes.bar}>
        <Box p={1} className={classes.title}>
          {title}
        </Box>
        <Box p={1}>
          <NavLink
            exact
            to="/"
            title="Debug tool"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <BugReportIcon />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            exact
            to="/heatmap"
            title="Heatmaps"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <BlurOnIcon />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/fleet-watch"
            title="Fleet Watch"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <ErrorOutlineIcon />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/devices"
            title="Device dashboard"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <SvgIcon component={CollarIcon} />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/training"
            title="Training"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <SchoolIcon />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/reports"
            title="Reports"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <Assessment />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/map"
            title="Mapping tool"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <MapIcon />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/live-view"
            title="Live farm view"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <Icon>fireplace</Icon>
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/towers"
            title="Tower Dashboard"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <SettingsInputAntenna />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/install-plan"
            title="Install Planner"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <EditLocationOutlined />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/behaviour"
            title="Behaviour"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <PetsIcon />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink to="/beef" title="Beef" className={classes.debugButton} activeStyle={{ color: '#fff' }}>
            <SteakIcon width={28} />
          </NavLink>
        </Box>
        <Box p={1}>
          <button type="button" onClick={handleAccountMenuOpen} title="User" className={classes.debugButton}>
            <AccountCircle />
          </button>
        </Box>
      </Box>
      {renderMenu}
    </div>
  );
};

export default NavigationBar;
