import React, { FunctionComponent, useMemo, useRef } from 'react';
import { MapRef } from 'react-map-gl';
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it
import 'mapbox-gl/dist/mapbox-gl.css';
import HeatMapService from 'services/heat-map.service';
import BreakFenceLayer from '../layers/break-fence.layer';
import { LayerSelection } from './input.panel';
import FarmLocationsLayer from '../layers/farm-locations.layer';
import PiezoHeatmapLayer from '../layers/piezo-heatmap.layer';
import ShockHeatmapLayer from '../layers/shock-heatmap.layer';
import PositionHeatmapLayer from '../layers/position-heatmap.layer';
import MapboxMap from '../components/mapbox-map';
import VibeHeatmapLayer from '../layers/vibe-heatmap.layer';
import FarmPaddocksLayer from '../layers/farm-paddocks.layer';
import BehaviourHeatmapLayer from '../layers/behaviour-heatmap.layer';
import HalterDroneMapLayer from '../layers/halter-drone-map.layer';

// @ts-ignore
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export type PopupInfo = {
  longitude: number;
  latitude: number;
  contents: JSX.Element;
};

export type DataMapSearch = {
  farmIds?: string[];
  mobIds?: string[];
  cattleNames?: string[];
  startTime?: Date;
  endTime?: Date;
};

type DataMapProps = {
  search: DataMapSearch;
  currentTime?: Date;
  layers?: LayerSelection;
  refreshLayers: LayerSelection;
};

export type Bounds = {
  minLng: number;
  minLat: number;
  maxLng: number;
  maxLat: number;
};

const DataMap: FunctionComponent<DataMapProps> = ({ search, currentTime, layers, refreshLayers }) => {
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const [recenterBounds, setRecenterBounds] = React.useState<Bounds | undefined>(undefined);
  const mapRef = useRef<MapRef>(null);

  const isLoading = HeatMapService.useIsLoading();

  useMemo(() => {
    if (isLoading) {
      setRecenterBounds(undefined);
    }
  }, [isLoading]);

  // Only recenter the map once
  const recenterMap = (bounds: Bounds) => {
    if (recenterBounds === undefined) {
      setRecenterBounds(bounds);
    }
  };

  useMemo(() => {
    if (recenterBounds && mapRef.current) {
      mapRef.current.fitBounds(
        [
          [recenterBounds.minLng, recenterBounds.minLat],
          [recenterBounds.maxLng, recenterBounds.maxLat],
        ],
        { padding: 40, duration: 1000, maxZoom: 15 }
      );
    }
  }, [recenterBounds]);

  return (
    <MapboxMap mapRef={mapRef} setMapLoaded={setMapLoaded}>
      {mapLoaded && layers && (
        <>
          <HalterDroneMapLayer farmId={search.farmIds?.length === 1 ? search.farmIds[0] : undefined} />
          {layers.farmPaddocks && (
            <FarmPaddocksLayer
              search={search}
              recenterMap={recenterMap}
              refresh={refreshLayers.farmPaddocks}
            />
          )}
          {layers.farmBreaks && (
            <BreakFenceLayer
              search={search}
              currentTime={currentTime}
              recenterMap={recenterMap}
              refresh={refreshLayers.farmBreaks}
            />
          )}
          {layers.farmLocations && (
            <FarmLocationsLayer
              search={search}
              recenterMap={recenterMap}
              refresh={refreshLayers.farmLocations}
            />
          )}
          {layers.behaviourHeatmap && (
            <BehaviourHeatmapLayer
              search={search}
              currentTime={currentTime}
              recenterMap={recenterMap}
              refresh={refreshLayers.behaviourHeatmap}
            />
          )}
          {layers.positionHeatmap && (
            <PositionHeatmapLayer
              search={search}
              currentTime={currentTime}
              recenterMap={recenterMap}
              refresh={refreshLayers.positionHeatmap}
            />
          )}
          {layers.piezoHeatmap && (
            <PiezoHeatmapLayer
              search={search}
              currentTime={currentTime}
              recenterMap={recenterMap}
              refresh={refreshLayers.piezoHeatmap}
            />
          )}
          {layers.vibeHeatmap && (
            <VibeHeatmapLayer
              search={search}
              currentTime={currentTime}
              recenterMap={recenterMap}
              refresh={refreshLayers.vibeHeatmap}
            />
          )}
          {layers.shockHeatmap && (
            <ShockHeatmapLayer
              search={search}
              currentTime={currentTime}
              recenterMap={recenterMap}
              refresh={refreshLayers.shockHeatmap}
            />
          )}
        </>
      )}
    </MapboxMap>
  );
};
export default DataMap;
