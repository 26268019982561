import React, { FunctionComponent, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Auth } from 'aws-amplify';
import ReactGA from 'react-ga';

import { isHalterEmail } from 'application/utils';
import { useParseQueryParameters } from 'application/hooks/use-parameters';

import { useDispatch } from 'store';
import farmContextSlice from 'store/slices/farm-context.slice';

import DebugCattleModule from 'application/modules/debug-cattle/debug-cattle.module';
import DevicesModule from 'application/modules/devices/devices.module';
import TrainingModule from 'application/modules/training/training.module';
import ReportsModule from 'application/modules/reports/reports.module';
import MapFrame from 'application/modules/map/map-frame';
import LiveViewModule from 'application/modules/live-view/live-view.module';
import DebugCattleModule2 from 'application/modules/debug-cattle/debug-cattle.module2';

import { CircularProgress } from '@material-ui/core';
import TowersModule from 'application/modules/towers/towers.module';
import FleetWatchModule from 'application/modules/fleet-watch/fleet-watch.module';
import BehaviourModule from 'application/modules/behaviour/behaviour.module';
import InstallPlannerModule from 'application/modules/install-planner/install-planner.module';
import HeatMapModule from 'application/modules/heat-map/heat-map.module';
import FleetToolsModule from 'application/modules/fleet-tools/fleet-tools.module';
import NavigationBarMobile from './components/navigation-bar-mobile';
import NavigationBar from './components/navigation-bar';
import LoginError from './login-error';
import BeefModule from '../modules/beef/beef.module';

const NavigationContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isHalterUser, setIsHalterUser] = useState(false);
  const [loading, setLoading] = useState(true);

  const { farmId } = useParseQueryParameters();

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: false }).then((u) => {
      if (isHalterEmail(u.username)) {
        setIsHalterUser(true);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    dispatch(farmContextSlice.actions.setCurrentFarm({ currentFarm: farmId }));
  }, [dispatch, farmId]);

  useEffect(() => {
    const currentScreen = location.pathname;
    const tabName =
      currentScreen.length === 1 ? 'Debug' : currentScreen.charAt(1).toUpperCase() + currentScreen.slice(2);
    document.title = tabName;
    ReactGA.ga('send', 'pageview', location.pathname);
  }, [location]);

  if (loading) {
    return <CircularProgress size="32px" />;
  }
  if (!isHalterUser) {
    Auth.signOut();
    return <LoginError />;
  }
  return (
    <>
      {!isMobile ? <NavigationBar /> : <NavigationBarMobile />}
      <Switch>
        <Route exact path="/">
          <DebugCattleModule />
        </Route>
        <Route exact path="/v2">
          <DebugCattleModule2 />
        </Route>
        <Route path="/fleet-watch">
          <FleetWatchModule />
        </Route>
        <Route path="/devices">
          <DevicesModule />
        </Route>
        <Route path="/training">
          <TrainingModule />
        </Route>
        <Route path="/map">
          <MapFrame />
        </Route>
        <Route exact path="/live-view">
          <LiveViewModule />
        </Route>
        <Route path="/reports">
          <ReportsModule />
        </Route>
        <Route path="/heatMap">
          <HeatMapModule />
        </Route>
        <Route path="/fleet-tools">
          <FleetToolsModule />
        </Route>
        <Route path="/towers">
          <TowersModule />
        </Route>
        <Route path="/install-plan">
          <InstallPlannerModule />
        </Route>
        <Route path="/behaviour">
          <BehaviourModule />
        </Route>
        <Route path="/beef">
          <BeefModule />
        </Route>
      </Switch>
    </>
  );
};

export default NavigationContainer;
