import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { TableContainer, LinearProgress, TextField } from '@material-ui/core';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import DeviceService from 'services/device.service';
import Wrapper from 'application/components/wrapper';
import Checkbox from 'application/components/checkbox';
import DevicesSearchPanel from '../panels/devices-search.panel';
import OverviewTable from '../tables/overview-table';
import SettingsTable from '../tables/settings-table';
import Tab from '../components/tab';

type DevicesScreenProps = {
  searchMode: 'farm' | 'device';
  autoRefresh?: boolean;
  onChangeAutoRefresh: (value: boolean) => void;
  displayTable: boolean;
  farmId: string;
  mobId?: string;
  cattleNames?: string[];
  serialNumbers?: string[];
  loadSettings: () => void;
};

const HorizontalStickyContainer = styled.div`
  position: sticky;
  left: 0;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  flex-wrap: wrap;
`;

const TotalCountLabel = styled.div`
  margin: 16px 0px 0px 40px;
  color: rgba(0, 0, 0, 0.75);
`;

const AutoRefreshWrapper = styled.div`
  margin: 4px 0px 0px 40px;
`;

const TableWrapper = styled.div<{ isSelectedFarmId: boolean }>`
  ${(isSelectedFarmId) => (isSelectedFarmId ? 'opacity: 1;' : 'opacity: 0.5;')}
`;

const LoadingBar = styled(LinearProgress)`
  position: sticky;
`;

moment.updateLocale('en', {
  relativeTime: {
    past(input) {
      return input === 'just now' ? input : `${input} ago`;
    },
    s: 'just now',
    future: 'in %s',
    ss: '%d secs',
    m: 'a minute',
    mm: '%d mins',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

const DevicesOverviewScreen: FunctionComponent<DevicesScreenProps> = ({
  searchMode,
  autoRefresh = false,
  onChangeAutoRefresh,
  displayTable,
  farmId,
  mobId,
  cattleNames,
  serialNumbers,
  loadSettings,
}) => {
  const loading = DeviceService.useIsOverviewLoading();
  const devicesList = DeviceService.useSelectOverview();

  const devices = useMemo(() => {
    const filteredDevices = devicesList.filter(
      (device) =>
        device.serialNumber !== farmId &&
        (mobId == null || device.mobId === mobId) &&
        (cattleNames == null ||
          cattleNames.length === 0 ||
          (device.cattleName != null && cattleNames.includes(device.cattleName)))
    );
    return sortBy(filteredDevices, (device) => device.serialNumber);
  }, [devicesList, farmId, mobId, cattleNames]);

  const [tagged, setTagged] = useState(false);
  const [legacyFaultsColumn, setLegacyFaultsColumn] = useState(false);
  const [firmwareVersion, setFirmwareVersion] = useState('');

  const filteredDevices = useMemo(
    () =>
      devices.filter((d) => {
        if (firmwareVersion && d.firmwareVersion && !d.firmwareVersion?.startsWith(firmwareVersion))
          return false;
        if (tagged) return d.cattleId != null;
        return true;
      }),
    [devices, firmwareVersion, tagged]
  );

  const [displayMode, setDisplayMode] = useState<'overview' | 'settings' | 'spares'>('overview');

  useEffect(() => {
    if (displayMode === 'settings') {
      loadSettings();
    }
  }, [displayMode]);

  const [selectedSearchMode, setSelectedSearchMode] = useState(searchMode);
  const [farmIdInSelect, setFarmIdInSelect] = useState(farmId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalResultsMessage = useMemo(
    () => `${filteredDevices.length} returned results`,
    [filteredDevices.length]
  );

  const spares = useMemo(() => devices.filter((d) => d.cattleId == null), [devices]);

  return (
    <Wrapper>
      <HorizontalStickyContainer>
        <LoadingBar variant={loading ? 'query' : 'determinate'} value={100} />
        <TabsWrapper>
          <Tab
            text="Search by farm"
            selected={selectedSearchMode === 'farm'}
            onPress={() => setSelectedSearchMode('farm')}
          />
          <Tab
            text="Search by device (slower)"
            selected={selectedSearchMode === 'device'}
            onPress={() => setSelectedSearchMode('device')}
          />
        </TabsWrapper>
        <DevicesSearchPanel
          searchMode={selectedSearchMode}
          mobId={mobId}
          cattleNames={cattleNames}
          serialNumbers={serialNumbers}
          onChangeFarmId={setFarmIdInSelect}
        />
        {displayTable && searchMode === selectedSearchMode && (
          <TabsWrapper>
            <Tab
              text="Overview"
              selected={displayMode === 'overview'}
              onPress={() => setDisplayMode('overview')}
            />
            <Tab
              text="Settings"
              selected={displayMode === 'settings'}
              onPress={() => setDisplayMode('settings')}
            />
            <Tab text="Spares" selected={displayMode === 'spares'} onPress={() => setDisplayMode('spares')} />
            <TotalCountLabel>
              {displayMode === 'spares' ? `${spares.length} returned results` : totalResultsMessage}
            </TotalCountLabel>
            <AutoRefreshWrapper>
              <Checkbox label="Auto refresh" checked={autoRefresh} onChangeValue={onChangeAutoRefresh} />
            </AutoRefreshWrapper>
            <Checkbox label="Tagged Only" checked={tagged} onChangeValue={setTagged} />
            <Checkbox
              label="Faults Column"
              checked={legacyFaultsColumn}
              onChangeValue={setLegacyFaultsColumn}
            />
            <TextField
              label="Firmware version"
              value={firmwareVersion}
              onChange={(event) => setFirmwareVersion(event.target.value)}
            />
          </TabsWrapper>
        )}
      </HorizontalStickyContainer>
      <TableContainer>
        {displayTable && (!loading || autoRefresh) && searchMode === selectedSearchMode && (
          <TableWrapper isSelectedFarmId={farmId !== farmIdInSelect}>
            {displayMode === 'overview' && (
              <OverviewTable
                devices={filteredDevices}
                mode={searchMode}
                legacyFaultsColumn={legacyFaultsColumn}
              />
            )}
            {displayMode === 'settings' && <SettingsTable devices={devices} mode={searchMode} />}
            {displayMode === 'spares' && (
              <OverviewTable devices={spares} mode={searchMode} legacyFaultsColumn={legacyFaultsColumn} />
            )}
          </TableWrapper>
        )}
      </TableContainer>
    </Wrapper>
  );
};

export default DevicesOverviewScreen;
