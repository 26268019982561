import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';

export const selectShockLocationsSlice = (state: AppState) => state.heatMap.shockLocations;
export const selectPiezoLocationsSlice = (state: AppState) => state.heatMap.piezoLocations;
export const selectVibeLocationsSlice = (state: AppState) => state.heatMap.vibeLocations;
export const selectPositionsSlice = (state: AppState) => state.heatMap.positionMetrics;
export const selectFarmsWithDatums = (state: AppState) => state.heatMap.farmsWithDatums;
export const selectFarmPaddocks = (state: AppState) => state.heatMap.farmPaddocks;
export const selectFarmBreaks = (state: AppState) => state.heatMap.farmBreaks;
export const selectExitPoints = (state: AppState) => state.heatMap.exitPoints;
export const selectBehaviourPositions = (state: AppState) => state.heatMap.behaviourPositions;

export const selectLayerStatusSlice = (state: AppState) => state.heatMap.layerStatuses;

export const selectIsLoadingSlice = (state: AppState) =>
  state.heatMap.layerStatuses.shockLocations === 'pending' ||
  state.heatMap.layerStatuses.piezoLocations === 'pending' ||
  state.heatMap.layerStatuses.vibeLocations === 'pending' ||
  state.heatMap.layerStatuses.positionMetrics === 'pending' ||
  state.heatMap.layerStatuses.farmsWithDatums === 'pending' ||
  state.heatMap.layerStatuses.farmPaddocks === 'pending' ||
  state.heatMap.layerStatuses.farmBreaks === 'pending' ||
  state.heatMap.layerStatuses.exitPoints === 'pending' ||
  state.heatMap.layerStatuses.behaviourPositions === 'pending' ||
  state.heatMap.layerStatuses.searchFromCommand === 'pending';

export const getShockLocations = createSelector(selectShockLocationsSlice, (items) => items);
export const getPiezoLocations = createSelector(selectPiezoLocationsSlice, (items) => items);
export const getVibeLocations = createSelector(selectVibeLocationsSlice, (items) => items);
export const getPositions = createSelector(selectPositionsSlice, (items) => items);
export const getFarmsWithDatums = createSelector(selectFarmsWithDatums, (items) => items);
export const getFarmPaddocks = createSelector(selectFarmPaddocks, (items) => items);
export const getFarmBreaks = createSelector(selectFarmBreaks, (items) => items);
export const getExitPoints = createSelector(selectExitPoints, (items) => items);
export const getBehaviourPositions = createSelector(selectBehaviourPositions, (items) => items);

export const isLoading = createSelector(selectIsLoadingSlice, (loading) => loading);

export const getLayersStatus = createSelector(selectLayerStatusSlice, (items) => items);
