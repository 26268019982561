import { Auth } from 'aws-amplify';
import Axios from 'axios';
import { BffDebugToolApi } from '@halter-corp/bff-debug-tool-service-client';
import { FarmApi, FarmStatusApi } from '@halter-corp/business-service-client';
import { JobApi, ThingRegistryApi } from '@halter-corp/thing-service-client';
import { DeviceApi, JobApi as OtaJobApi, ReleaseChannelApi } from '@halter-corp/ota-service-client';
import {
  DeviceAlarmsApi,
  DeviceOffFarmApi,
  DeviceOnFarmApi,
  FleetMetricsApi,
} from '@halter-corp/fleet-service-client';
import { FeatureApi } from '@halter-corp/topography-service-client';
import { MobApi, CattleApi } from '@halter-corp/cattle-service-client';
import {
  DeviceRegistryApi,
  DeviceStatusApi,
  MessagingApi,
  DeviceStatsApi,
} from '@halter-corp/device-service-client';
import {
  DevicesApi as CowtrollerDeviceApi,
  GroupsApi as CowtrollerGroupsApi,
  CommandApi as CowtrollerCommandsApi,
  DevicePayloadApi as CowtrollerDevicePayloadApi,
} from '@halter-corp/cowtroller-service-client';
import {
  TrainingProfileApi,
  TrainingModifierApi,
  FarmerTrainingGuideApi,
  ApplyModifiersApi,
  ApplyProfilesApi,
} from '@halter-corp/training-service-client';
import * as FarmInfra from '@halter-corp/farm-infra-service-client';
import * as Tower from '@halter-corp/tower-service-client';
import * as Infrastructure from '@halter-corp/infrastructure-service-client';
import { SettingsApi } from '@halter-corp/settings-service-client';
import { InitialStateApi } from '@halter-corp/bff-mobile-service-client';
import { CalvingApi, SeasonApi } from '@halter-corp/cattle-breeding-service-client';
import { DefaultApi as CattleHealthAPI } from '@halter-corp/cow-health-service-client';
import { TaskApi } from '@halter-corp/task-service-client';
import { MindaAdapterApi } from '@halter-corp/minda-adapter-client';
import { InterventionApi } from '@halter-corp/mating-service-client';
import { CollarActiveAlarmApi } from '@halter-corp/collar-alarm-service-client';
import { GrazingHeatmapMetricsApi } from '@halter-corp/cow-location-service-client';
import getHalterApiUrl from './configuration.service';

const HttpApiService = {
  getAuthorizationHeader: async () => {
    const session = await Auth.currentSession();
    return { Authorization: `Bearer ${session.getIdToken().getJwtToken()}` };
  },

  getContextOverrideHeader: (farmId?: string) => ({
    'context-override': JSON.stringify({
      farmId: farmId ?? window.localStorage.getItem('currentFarm'),
    }),
  }),

  getNoFarmContextOverrideHeader: () => ({
    'context-override': JSON.stringify({ farmId: null }),
  }),

  getBffDebugToolApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });

    return new BffDebugToolApi(undefined, getHalterApiUrl(), axios as any);
  },

  getFarmApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });

    return new FarmApi(undefined, getHalterApiUrl(), axios as any);
  },

  getFarmStatusApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });

    return new FarmStatusApi(undefined, getHalterApiUrl(), axios as any);
  },

  getMobApi: async (farmId: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new MobApi(undefined, getHalterApiUrl(), axios as any);
  },

  getFeatureApi: async (farmId?: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new FeatureApi(undefined, getHalterApiUrl(), axios as any);
  },

  getDeviceRegistryApi: async (farmId?: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);
    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });
    return new DeviceRegistryApi(undefined, getHalterApiUrl(), axios as any);
  },

  getDeviceStatusApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new DeviceStatusApi(undefined, getHalterApiUrl(), axios as any);
  },

  getCowtrollerDeviceApi: async (farmId?: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new CowtrollerDeviceApi(undefined, getHalterApiUrl(), axios as any);
  },

  getCowtrollerGroupsApi: async (farmId: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });
    return new CowtrollerGroupsApi(undefined, getHalterApiUrl(), axios as any);
  },

  getCowtrollerCommandsApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new CowtrollerCommandsApi(undefined, getHalterApiUrl(), axios as any);
  },

  getCowtrollerDevicePayloadApi: async (farmId?: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new CowtrollerDevicePayloadApi(undefined, getHalterApiUrl(), axios as any);
  },

  getDeviceMessagingApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new MessagingApi(undefined, getHalterApiUrl(), axios as any);
  },

  getDeviceStatsApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new DeviceStatsApi(undefined, getHalterApiUrl(), axios as any);
  },

  getCattleApi: async (farmId?: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new CattleApi(undefined, getHalterApiUrl(), axios as any);
  },

  getTrainingProfileApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });

    return new TrainingProfileApi(undefined, getHalterApiUrl(), axios as any);
  },

  getTrainingModifierApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });

    return new TrainingModifierApi(undefined, getHalterApiUrl(), axios as any);
  },

  getThingRegistryApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new ThingRegistryApi(undefined, getHalterApiUrl(), axios as any);
  },

  getOtaDeviceApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new DeviceApi(undefined, getHalterApiUrl(), axios as any);
  },

  getOtaJobApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new OtaJobApi(undefined, getHalterApiUrl(), axios as any);
  },

  getOtaReleaseChannelApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new ReleaseChannelApi(undefined, getHalterApiUrl(), axios as any);
  },

  getCollarActiveAlarmsApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new CollarActiveAlarmApi(undefined, getHalterApiUrl(), axios as any);
  },

  getDeviceAlarmsApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new DeviceAlarmsApi(undefined, getHalterApiUrl(), axios as any);
  },

  getDeviceOffFarmApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new DeviceOffFarmApi(undefined, getHalterApiUrl(), axios as any);
  },

  getDeviceOnFarmApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new DeviceOnFarmApi(undefined, getHalterApiUrl(), axios as any);
  },

  getFleetMetricsApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new FleetMetricsApi(undefined, getHalterApiUrl(), axios as any);
  },

  getApplyProfilesApi: async (farmId?: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new ApplyProfilesApi(undefined, getHalterApiUrl(), axios as any);
  },

  getApplyModifiersApi: async (farmId?: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new ApplyModifiersApi(undefined, getHalterApiUrl(), axios as any);
  },

  getFarmerTrainingGuideApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });

    return new FarmerTrainingGuideApi(undefined, getHalterApiUrl(), axios as any);
  },

  getSettingsApi: async (farmId?: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });

    return new SettingsApi(undefined, getHalterApiUrl(), axios as any);
  },

  getBffMobileInitialStateApi: async (farmId: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });
    return new InitialStateApi(undefined, getHalterApiUrl(), axios as any);
  },

  getJobApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new JobApi(undefined, getHalterApiUrl(), axios as any);
  },

  getFarmInfraShardApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new FarmInfra.ShardApi(undefined, getHalterApiUrl(), axios as any);
  },

  getFarmInfraFarmApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new FarmInfra.FarmApi(undefined, getHalterApiUrl(), axios as any);
  },

  getFarmInfraDeviceApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new FarmInfra.DeviceApi(undefined, getHalterApiUrl(), axios as any);
  },

  getFarmInfraNetworkGroupApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new FarmInfra.NetworkGroupApi(undefined, getHalterApiUrl(), axios as any);
  },

  getTowerTowerAPI: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new Tower.TowerApi(undefined, getHalterApiUrl(), axios as any);
  },

  getTowerDeviceContextAPI: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new Tower.DeviceContextApi(undefined, getHalterApiUrl(), axios as any);
  },

  getTowerDeviceStatusAPI: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new Tower.DeviceStatusApi(undefined, getHalterApiUrl(), axios as any);
  },

  getTowerDeviceSettingsAPI: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new Tower.DeviceSettingsApi(undefined, getHalterApiUrl(), axios as any);
  },

  getLoraCoverageAPI: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new Tower.LoraCoverageApi(undefined, getHalterApiUrl(), axios as any);
  },

  getInfraProductApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new Infrastructure.InfraProductApi(undefined, getHalterApiUrl(), axios as any);
  },

  getInfrastructureTowerApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new Infrastructure.TowerApi(undefined, getHalterApiUrl(), axios as any);
  },

  getInfrastructureTowerMappingApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new Infrastructure.TowerMappingApi(undefined, getHalterApiUrl(), axios as any);
  },

  getSeasonApi: async (farmId: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });
    return new SeasonApi(undefined, getHalterApiUrl(), axios as any);
  },

  getCowHealthApi: async (farmId: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });
    return new CattleHealthAPI(undefined, getHalterApiUrl(), axios as any);
  },

  getMindaAdapterApi: async (farmId: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });
    return new MindaAdapterApi(undefined, getHalterApiUrl(), axios as any);
  },

  getCalvingApi: async (farmId: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });
    return new CalvingApi(undefined, getHalterApiUrl(), axios as any);
  },

  getTaskApi: async (farmId?: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });
    return new TaskApi(undefined, getHalterApiUrl(), axios as any);
  },

  getInterventionApi: async (farmId: string) => {
    const authorization = await HttpApiService.getAuthorizationHeader();
    const contextOverride = HttpApiService.getContextOverrideHeader(farmId);

    const axios = Axios.create({
      headers: {
        ...authorization,
        ...contextOverride,
      },
    });
    return new InterventionApi(undefined, getHalterApiUrl(), axios as any);
  },

  getNetworkDeviceContextApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new Tower.NetworkDeviceContextApi(undefined, getHalterApiUrl(), axios as any);
  },

  getGrazingHeatmapMetricsApi: async () => {
    const authorization = await HttpApiService.getAuthorizationHeader();

    const axios = Axios.create({
      headers: {
        ...authorization,
      },
    });
    return new GrazingHeatmapMetricsApi(undefined, getHalterApiUrl(), axios as any);
  },
};

export default HttpApiService;
