import React, { FunctionComponent, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid } from '@material-ui/core';
import styled from 'styled-components';
import HeatMapService from 'services/heat-map.service';
import Header from 'application/components/header';
import { useLocation, useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GenericInput from '../components/generic-input';
import FarmSelect from '../components/farm-select';
import DateTimeSelect from '../components/datetime-picker';
import LayerCheckbox from '../components/layer-checkbox';
import MobSelect from '../components/mob-select';

const Row = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

export type LayerSelection = {
  farmLocations: boolean;
  farmPaddocks: boolean;
  farmBreaks: boolean;
  shockHeatmap: boolean;
  vibeHeatmap: boolean;
  piezoHeatmap: boolean;
  positionHeatmap: boolean;
  behaviourHeatmap: boolean;
};

const SEPARATOR = ',';

// default layerselection
const defaultLayers: LayerSelection = {
  farmLocations: false,
  farmPaddocks: true,
  farmBreaks: true,
  shockHeatmap: true,
  vibeHeatmap: true,
  piezoHeatmap: true,
  positionHeatmap: true,
  behaviourHeatmap: false,
};

type InputPanelProps = {
  refreshLayers: LayerSelection;
  setRefreshLayers: (layerSelection: LayerSelection) => void;
};

const InputPanel: FunctionComponent<InputPanelProps> = ({ refreshLayers, setRefreshLayers }) => {
  const history = useHistory();
  const location = useLocation();

  const urlQueryString = new URLSearchParams(location.search);

  const [farmIds, setFarmIds] = useState<string[]>(urlQueryString.get('farmIds')?.split(SEPARATOR) || []);
  const [mobIds, setMobIds] = useState<string[]>(urlQueryString.get('mobIds')?.split(SEPARATOR) || []);
  const [cattleEarTags, setCattleEarTags] = useState<string>(urlQueryString.get('earTags') || '');
  const [fromDate, setFromDate] = useState<Date>(
    urlQueryString.get('fromDate') ? new Date(urlQueryString.get('fromDate') as string) : new Date()
  );
  const [toDate, setToDate] = useState<Date>(
    urlQueryString.get('toDate') ? new Date(urlQueryString.get('toDate') as string) : new Date()
  );

  const layersStatus = HeatMapService.useFetchLayersStatus();

  const encodedLayers = urlQueryString.get('layers');
  const [layerSelection, setLayerSelection] = useState<LayerSelection>(
    encodedLayers ? JSON.parse(decodeURIComponent(escape(atob(encodedLayers)))) : defaultLayers
  );

  const handleSearch = () => {
    const params = new URLSearchParams({
      farmIds: farmIds?.join(SEPARATOR) ?? '',
      mobIds: mobIds?.join(SEPARATOR) ?? '',
      earTags: cattleEarTags ?? '',
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
      layers: btoa(unescape(encodeURIComponent(JSON.stringify(layerSelection)))),
    });

    history.replace({
      pathname: window.location.pathname,
      search: params.toString(),
    });
  };

  return (
    <Accordion
      style={{
        position: 'absolute',
        background: 'white',
        margin: '30px',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
        padding: '10px',
        width: '400px',
      }}
      defaultExpanded
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Header text="Heatmap" size="large" style={{ fontSize: '18px' }} />
      </AccordionSummary>
      <AccordionDetails
        style={{
          maxHeight: 'calc(100vh - 260px)',
          overflowY: 'scroll',
          display: 'block',
        }}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <FarmSelect
              onChange={(farms) => {
                setFarmIds(farms?.map((farm) => farm.id) ?? []);
                setMobIds([]);
              }}
              value={farmIds}
            />
          </Grid>
          {farmIds?.length === 1 && (
            <>
              <Grid item>
                <MobSelect
                  farmId={farmIds[0]}
                  onChange={(mobs) => setMobIds(mobs?.map((mob) => mob.id) ?? [])}
                  value={mobIds}
                />
              </Grid>
              <Grid item>
                <GenericInput
                  label="Cattle names"
                  onChange={(change) => setCattleEarTags(change.replace(' ', ''))}
                  value={cattleEarTags}
                />
              </Grid>
            </>
          )}

          <Grid item>
            <DateTimeSelect label="Date from" onChange={setFromDate} value={fromDate} />
          </Grid>
          <Grid item>
            <DateTimeSelect label="Date to" onChange={setToDate} value={toDate} />
          </Grid>
        </Grid>
        <Header text="Layers" size="large" style={{ marginTop: '20px', fontSize: '18px' }} />
        <LayerCheckbox
          field="farmLocations"
          layers={layerSelection}
          setLayers={setLayerSelection}
          setRefreshLayers={setRefreshLayers}
          refreshLayers={refreshLayers}
          status={layersStatus.farmsWithDatums}
        />
        <LayerCheckbox
          field="farmPaddocks"
          layers={layerSelection}
          setLayers={setLayerSelection}
          setRefreshLayers={setRefreshLayers}
          refreshLayers={refreshLayers}
          status={layersStatus.farmPaddocks}
        />
        <LayerCheckbox
          field="farmBreaks"
          layers={layerSelection}
          setLayers={setLayerSelection}
          setRefreshLayers={setRefreshLayers}
          refreshLayers={refreshLayers}
          status={layersStatus.farmBreaks}
        />
        <LayerCheckbox
          field="positionHeatmap"
          layers={layerSelection}
          setLayers={setLayerSelection}
          setRefreshLayers={setRefreshLayers}
          refreshLayers={refreshLayers}
          status={layersStatus.positionMetrics}
        />
        <LayerCheckbox
          field="shockHeatmap"
          layers={layerSelection}
          setLayers={setLayerSelection}
          setRefreshLayers={setRefreshLayers}
          refreshLayers={refreshLayers}
          status={layersStatus.shockLocations}
        />
        <LayerCheckbox
          field="vibeHeatmap"
          layers={layerSelection}
          setLayers={setLayerSelection}
          setRefreshLayers={setRefreshLayers}
          refreshLayers={refreshLayers}
          status={layersStatus.vibeLocations}
        />
        <LayerCheckbox
          field="piezoHeatmap"
          layers={layerSelection}
          setLayers={setLayerSelection}
          setRefreshLayers={setRefreshLayers}
          refreshLayers={refreshLayers}
          status={layersStatus.piezoLocations}
        />
        <LayerCheckbox
          field="behaviourHeatmap"
          layers={layerSelection}
          setLayers={setLayerSelection}
          setRefreshLayers={setRefreshLayers}
          refreshLayers={refreshLayers}
          status={layersStatus.behaviourPositions}
        />

        <Divider style={{ margin: '10px 0px' }} />
        <Row>
          <Button
            color="primary"
            component="a"
            style={{ fontSize: '12px' }}
            href={`https://farmhand.prod.halter.io/map/debug?${new URLSearchParams({
              farmId: farmIds[0],
              ...(mobIds?.length === 1 && { mobId: mobIds[0] }),
              minDate: fromDate.toISOString(),
              maxDate: toDate.toISOString(),
            }).toString()}`}
            target="_blank"
          >
            Farmhand (New)
          </Button>
          <Button
            color="primary"
            component="a"
            style={{ fontSize: '12px' }}
            href={`/?${new URLSearchParams({
              farmId: farmIds?.join(SEPARATOR) ?? '',
              mobId: mobIds?.join(SEPARATOR) ?? '',
              earTags: cattleEarTags ?? '',
              fromDate: fromDate.toISOString(),
              toDate: toDate.toISOString(),
              layers: btoa(unescape(encodeURIComponent(JSON.stringify(layerSelection)))),
            }).toString()}`}
            disabled={farmIds?.length > 1}
            target="_blank"
          >
            Debug (Old)
          </Button>
          <Button color="primary" style={{ marginLeft: 'auto' }} onClick={handleSearch}>
            Search
          </Button>
        </Row>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(InputPanel);
