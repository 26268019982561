/* eslint-disable max-len */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, makeStyles, Menu, MenuItem, Divider } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import MapIcon from '@material-ui/icons/Map';
import SchoolIcon from '@material-ui/icons/School';
import BuildIcon from '@material-ui/icons/Build';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ExitToApp, Assessment, SettingsInputAntenna } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@material-ui/core/Icon';
import { Auth } from 'aws-amplify';
import { ReactComponent as CollarIcon } from './collar.icon.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
  },
  bar: {
    backgroundColor: '#444',
    display: 'flex',
    padding: '0 1rem',
  },
  title: {
    color: '#fff',
    flexGrow: 1,
    paddingTop: '0.75rem',
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
  },
  debugButton: {
    textDecoration: 'none',
    color: '#aaa',
    '&:hover': {
      color: '#fff',
    },
    '&:active': {
      color: '#fff',
    },
    background: 'transparent',
    border: 'none',
  },
  menuPaper: {
    backgroundColor: '#444',
  },
}));

const NavigationBarMobile: FunctionComponent = () => {
  const [user, setUser] = useState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const classes = useStyles();

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: false }).then((u) => {
      setUser(u.attributes.given_name);
    });
  }, []);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Auth.signOut();
    handleMenuClose();
  };

  const menuId = 'account-menu';
  const renderMenu = (
    <Menu
      classes={{ paper: classes.menuPaper }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box style={{ textAlign: 'left', padding: '8px 16px', color: '#fff' }}>Hi {user}!</Box>
      <Divider />
      <Box p={1}>
        <NavLink to="/map" title="Mapping tool" className={classes.debugButton}>
          <MapIcon /> Mapping Tool
        </NavLink>
      </Box>
      <Divider />
      <Divider />
      <Divider />
      <Divider />
      <Box p={1}>
        <NavLink to="/towers" title="Tower Dashboard" className={classes.debugButton}>
          <SettingsInputAntenna /> Tower Dashboard
        </NavLink>
      </Box>
      <Divider />
      <MenuItem onClick={handleLogout} className={classes.debugButton}>
        <ExitToApp style={{ paddingRight: '8px' }} />
        Sign out
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.wrapper}>
      <Box className={classes.bar}>
        <Box p={1} className={classes.title}>
          Caddie
        </Box>
        <Box p={1}>
          <NavLink
            exact
            to="/fleet-tools"
            title="Fleet tools"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <BuildIcon />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/fleet-watch"
            title="Fleet Watch"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <ErrorOutlineIcon />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/devices"
            title="Device dashboard"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <SvgIcon component={CollarIcon} />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/training"
            title="Training"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <SchoolIcon />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/reports"
            title="Reports"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <Assessment />
          </NavLink>
        </Box>
        <Box p={1}>
          <NavLink
            to="/live-view"
            title="Live farm view"
            className={classes.debugButton}
            activeStyle={{ color: '#fff' }}
          >
            <Icon>fireplace</Icon>
          </NavLink>
        </Box>
        <Box p={1}>
          <button type="button" onClick={handleMenuOpen} title="Menu" className={classes.debugButton}>
            <MenuIcon />
          </button>
        </Box>
      </Box>
      {renderMenu}
    </div>
  );
};

export default NavigationBarMobile;
