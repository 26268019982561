import { CommandTypeEnum, ICommandDTO } from '@halter-corp/cowtroller-service-client';

const errorMessage = (command: ICommandDTO): string =>
  // New type or miss mapped a possible combination
  `Something went wrong, ask an engineer, ${(command.type, command.context.origin)}`;

const getCommandsToRemove = (commandArguments: any): string => {
  const commandsToRemove = commandArguments.commandsToRemove as Array<{
    commandId: string;
    collarCommandId: string;
  }>;
  const collarCommandIds =
    commandsToRemove.length > 1
      ? commandsToRemove.map((it) => it.collarCommandId)
      : commandsToRemove[0].collarCommandId;
  return JSON.stringify(collarCommandIds);
};

const prettyPrintSetZone = (command: ICommandDTO): string => {
  switch (command.context.origin) {
    case 'BREAK_SHAPE_UPDATED':
      return 'Break update - shape or assigned mobs updated';
    case 'UPCOMING_BREAK': {
      const { useSharedBoundaryControlToZone } = command.arguments as any;
      const { isEnterPaddockOnly } = command.arguments as any;
      return useSharedBoundaryControlToZone
        ? 'Break to break to break - Next break'
        : `Upcoming - Next break ${isEnterPaddockOnly ? '(Enter paddock only)' : ''}`;
    }
    case 'DROP_FENCE_SHIFT_TO_NEXT': {
      return 'Break to break - Next break';
    }
    case 'BREAK_TO_BREAK_NEW_AREA_SHIFT':
      return 'Break to break to break - New area';
    case 'DROP_FENCE_EXIT_PADDOCK':
    case 'DROP_FENCE_NO_SHIFT': {
      const { isEnterPaddockOnly } = command.arguments as any;
      return `Paddock cooldown - ${isEnterPaddockOnly ? '"Enter only"' : 'Next'} break`;
    }
    default:
      return errorMessage(command);
  }
};

const prettyPrintExitZone = (command: ICommandDTO): string => {
  switch (command.context.origin) {
    case 'EXIT_PADDOCK_SHIFT': {
      const isDraft = command.groupId == null;
      if (isDraft) {
        return 'Exit paddock shift - Drafting';
      }

      if (
        (command.arguments as any).exitPath != null &&
        (command.arguments as any).exitPath.coordinates.length > 0
      ) {
        return 'Exit paddock shift - With race path';
      }

      return 'Exit paddock shift - To gate';
    }

    default:
      return errorMessage(command);
  }
};

const prettyPrintPauseCommands = (command: ICommandDTO): string => {
  switch (command.context.origin) {
    case 'INCIDENT':
      return 'Pre-emptive pause incident';
    default:
      return errorMessage(command);
  }
};

const prettyPrintDeleteCommands = (command: ICommandDTO): string => {
  switch (command.context.origin) {
    case 'INCIDENT':
      return `Resolving pause incident - ${getCommandsToRemove(command.arguments)}`;
    case 'EXIT_PADDOCK_SHIFT':
      return `Finishing exit paddock command - ${getCommandsToRemove(command.arguments)}`;
    case 'BREAK_DELETED':
    case 'BREAK_TO_BREAK_NEW_AREA_SHIFT':
      return `B2B2B V1 : Break deleted - ${getCommandsToRemove(command.arguments)}`;
    case 'BACK_TO_PADDOCK_SHIFT':
      return `Completing shift back to paddock - ${getCommandsToRemove(command.arguments)}`;
    default:
      return errorMessage(command);
  }
};

const prettyPrintClearCommands = (command: ICommandDTO): string => {
  switch (command.context.origin) {
    case 'DROP_FENCE_NO_SHIFT':
      return 'Drop break - Manual shift';
    case 'BREAK_CANCELLED':
      return 'Break cancelled';
    default:
      return errorMessage(command);
  }
};

const prettyPrintBackToPaddock = (command: ICommandDTO): string => {
  switch (command.context.origin) {
    case 'BACK_TO_PADDOCK_SHIFT':
      return 'Back to paddock shift';
    default:
      return errorMessage(command);
  }
};

export const prettyPrintOrigin = (command: ICommandDTO): string => {
  switch (command.type) {
    case 'SET_ZONE':
      return prettyPrintSetZone(command);
    case 'EXIT_ZONE':
      return prettyPrintExitZone(command);
    case 'PAUSE':
      return prettyPrintPauseCommands(command);
    case 'DELETE_COMMANDS':
      return prettyPrintDeleteCommands(command);
    case 'CLEAR_STATE':
      return prettyPrintClearCommands(command);
    case 'BACK_TO_PADDOCK':
      return prettyPrintBackToPaddock(command);
    default:
      return errorMessage(command);
  }
};

export const prettyPrintType = (type: CommandTypeEnum): string => {
  switch (type) {
    case CommandTypeEnum.CLEAR_STATE:
      return 'Clear Zone';
    case CommandTypeEnum.SET_ZONE:
      return 'Set Zone';
    case CommandTypeEnum.EXIT_ZONE:
      return 'Exit Paddock';
    case CommandTypeEnum.DELETE_COMMANDS:
      return 'Delete Commands';
    case CommandTypeEnum.PAUSE:
      return 'Pause Command';
    case CommandTypeEnum.BACK_TO_PADDOCK:
      return 'Back To Paddock';
    default:
      return type;
  }
};
